import React from 'react';

import AppPaths from '../../../AppPaths';
import { BillingSubscription } from './SplashPage';
import Button from '../../../components/Button';
import ErrorMessage, { useError } from '../../../components/ErrorMessage';
import { centsToDollars } from '../../../lib/util';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import { useHistory, useLocation } from 'react-router-dom';
import ClaimDiscountModal from './components/ClaimDiscountModal';

interface DiscountDeterrentState {
  subscription: BillingSubscription;
}

export default function DiscountDeterrent() {
  const pageName = '33% Off Discount Deterrent';
  const history = useHistory();
  const location = useLocation<DiscountDeterrentState>();
  const { subscription } = location.state;
  const { error, errorID } = useError();

  events.pageLoad({ subscriptionId: subscription.id }, pageName);

  const handleClaim = () => {
    events.discountDeterrentAccepted({ subscriptionId: subscription.id });
    console.log('TODO: trigger mutation');
  };

  const handleCancel = () => {
    events.discountDeterrentDeclined({ subscriptionId: subscription.id });
    history.push({
      pathname: AppPaths.Supplements.Survey(subscription.id),
      state: { subscription },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.goBackFromPage({ subscriptionId: subscription.id }, pageName)}
          closeButtonAnalyticsEvent={() => events.closePage({ subscriptionId: subscription.id }, pageName)}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.largeHeader}>
            <h1>How about 33% off your next shipment?</h1>
          </div>

          {/* TODO: Add loader while mutation runs */}
          <div className={styles.content}>
            <p>
              Don't lose out on benefits to your dog's health – here's a discount on your next bag to keep your
              subscription going.
            </p>

            <div className={styles.highlight}>
              <div className={styles.header}>Next shipment with $10 discount!</div>
              <div className={styles.discountedPrice}>
                ${centsToDollars((subscription.subscriptionOption.priceInCents / 3) * 2)}{' '}
                <span className={styles.originalPrice}>
                  ${centsToDollars(subscription.subscriptionOption.priceInCents)}
                </span>
              </div>
            </div>
          </div>
          {error && <ErrorMessage errors={[error]} errorID={errorID} />}
        </div>
        <div className={styles.buttonContainer}>
          <ClaimDiscountModal
            onContinue={handleClaim}
            subscription={subscription}
            trigger={<Button className={styles.button}>Claim 33% discount</Button>}
          />
          <Button className={styles.button} onClick={() => handleCancel()} tertiary>
            Continue with Cancellation
          </Button>
        </div>
      </div>
    </>
  );
}
