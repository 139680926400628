import { FeaturesReady, GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import AppPaths from './AppPaths';
import { ChatEmbed } from './ChatEmbed';
import SentryRoute from './SentryRoute';
import styles from './Series3App.module.scss';
import AppBar from './components/AppBar';
import CJEventComponent from './components/CJEventComponent';
import AutoAppliedCouponBanner from './components/CouponBanner/AutoAppliedCouponBanner';
import Footer from './components/Footer';
import { ImpactIdentifyComponent } from './components/Impact';
import Loading from './components/Loading';
import ReferralRedirect from './components/ReferralRedirect';
import ReviewsCarousel from './components/ReviewsCarousel';
import HideChatWidget from './components/ZendeskChat/HideChatWidget';
import { Series3ContextProvider, useSeries3Context } from './contexts/Series3Context';
import useAnalytics from './hooks/useAnalytics';
import { CartModeProvider, CartModes } from './lib/cartModes';
import { getFiGrowthBook } from './lib/growthbook';
import { FiAppProps } from './lib/router';
import './styles/fonts.scss';
import * as types from './types';
import Accessories from './views/Accessories';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import ConfigReloadRetry from './views/ConfigReloadRetry/ConfigReloadRetry';
import ForgotPassword from './views/ForgotPassword';
import GiftCard from './views/GiftCard';
import Home from './views/Home';
import Login from './views/Login';
import MembershipUpgrade from './views/MembershipUpgrade/MembershipUpgrade';
import MonthlyUpgrade from './views/MonthlyUpgrade/MonthlyUpgrade';
import ProductDetails from './views/ProductDetails/ProductDetails';
import PurchaseSubscriptionBlocker from './views/PurchaseSubscription/PurchaseSubscriptionBlocker/PurchaseSubscriptionBlocker';
import PurchaseSubscriptionRoutes from './views/PurchaseSubscription/PurchaseSubscriptionRoutes';
import Referrals from './views/Referrals/Referrals';
import { RescuePurchaseGiftCards } from './views/Rescue';
import RescuePlacement from './views/Rescue/Placement/RescuePlacement/RescuePlacement';
import Subscription from './views/ManageSubscription/Collar/Subscription';
import Upgrade from './views/Upgrade';
import Supplements from './views/ManageSubscription/Supplements/Supplements';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const LEGACY_COLLAR_ROUTE = AppPaths.Products('smart-collar');

// This previous password reset path was accidentally changed, but had been published in help articles. This constant
// will be used to redirect to the new route.
const LEGACY_FORGOT_PASSWORD_ROUTE = '/passwordReset';

function PageScrollBehavior() {
  const { hash, pathname, state } = useLocation<{ noScroll?: boolean } | undefined>();

  // react-router does not natively handle scrolling to anchors on route change
  // This solution was borrowed from: https://stackoverflow.com/a/61311926/1480571
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
      return;
    }

    if (!state?.noScroll) {
      window.scrollTo(0, 0);
    }
  }, [hash, pathname, state?.noScroll]);

  return <React.Fragment />;
}

interface ConfigLoaderProps {
  children: JSX.Element;
  requireLogin?: boolean;
}

function ConfigLoader({ children, requireLogin }: ConfigLoaderProps) {
  const location = useLocation();

  const { appInitialized, initializationError, initializing, retryInitialization } = useSeries3Context();
  const session = useSelector((state: types.AppState) => state.session);
  const { analyticsState } = useAnalytics(location, appInitialized);

  // Check if we're logged in and the component requires us to be logged in
  if (appInitialized && requireLogin && !session) {
    return <Redirect to={`${AppPaths.Login}?returnTo=${encodeURIComponent(location.pathname)}`} />;
  }

  // If we're unable to initialize the app, we won't be able to render the site properly so we will show a special
  // component that allows the user to retry fetching the site config from our api.
  if (initializationError) {
    return <ConfigReloadRetry reload={retryInitialization} />;
  }

  if (initializing || analyticsState === 'loading') {
    return <Loading />;
  }

  return children;
}

/**
 * Wraps a component with the app context provider, which loads necessary site configuration, and shows a loading
 * throbber until the app is ready to render.
 */
function requireConfig(Component: React.ComponentType<FiAppProps>) {
  return function RequireConfig({ children, requireLogin, upgradeContext }: FiAppProps) {
    return (
      <Series3ContextProvider upgradeFlow={upgradeContext}>
        <ConfigLoader requireLogin={requireLogin}>
          <>
            <Component>{children}</Component>
            {/* PageScrollBehavior manually handles scrolling to anchors for react-router page changes */}
            {/* it should go after config was fetched and page content drawn so the referenced element id exists */}
            <PageScrollBehavior />
          </>
        </ConfigLoader>
      </Series3ContextProvider>
    );
  };
}

function App() {
  useEffect(() => {
    // Load features from the GrowthBook API and initialize the SDK
    getFiGrowthBook().loadFeatures();
  }, []);

  // Set the attributes of the request/user
  getFiGrowthBook().setAttributes();

  return (
    <GrowthBookProvider growthbook={getFiGrowthBook().growthBook}>
      <AutoAppliedCouponBanner />
      <AppBar />
      <FeaturesReady>
        <ChatEmbed />
      </FeaturesReady>
      <Switch>
        <SentryRoute exact path={LEGACY_COLLAR_ROUTE}>
          <Redirect to={AppPaths.Products(types.series3CollarId)} />
        </SentryRoute>
        <SentryRoute path={AppPaths.Products(':id')}>
          <ProductDetails />
          <ReviewsCarousel />
          <Footer />
        </SentryRoute>
        <SentryRoute path={AppPaths.ApplyReferralCode}>
          <ReferralRedirect />
        </SentryRoute>
        <SentryRoute path={AppPaths.Bag}>
          <Cart />
          <HideChatWidget mobileOnly={true} />
        </SentryRoute>
        <SentryRoute path={AppPaths.Checkout}>
          <FeaturesReady>
            <Checkout />
          </FeaturesReady>
        </SentryRoute>
        <SentryRoute exact path={LEGACY_FORGOT_PASSWORD_ROUTE}>
          <Redirect to={AppPaths.ForgotPassword} />
        </SentryRoute>
        <SentryRoute exact path={AppPaths.ForgotPassword}>
          <ForgotPassword />
        </SentryRoute>
        <SentryRoute exact path={AppPaths.Gift}>
          <GiftCard />
          <Footer />
        </SentryRoute>
        <SentryRoute path={AppPaths.Accessories}>
          <Accessories />
          <Footer />
        </SentryRoute>
        <SentryRoute exact path={AppPaths.Rescue.PurchaseGiftCards}>
          <RescuePurchaseGiftCards />
          <Footer />
        </SentryRoute>
        <SentryRoute exact path={AppPaths.Login}>
          <Login />
        </SentryRoute>
        <SentryRoute exact path={AppPaths.Home}>
          <Home />
        </SentryRoute>
      </Switch>
    </GrowthBookProvider>
  );
}

function Series3UpgradeApp() {
  useEffect(() => {
    // Load features from the GrowthBook API and initialize the SDK
    getFiGrowthBook().loadFeatures();
  }, []);

  // Set the attributes of the request/user
  getFiGrowthBook().setAttributes();

  return (
    <GrowthBookProvider growthbook={getFiGrowthBook().growthBook}>
      <CartModeProvider mode={CartModes.series3}>
        <HideChatWidget />
        <Upgrade />
      </CartModeProvider>
    </GrowthBookProvider>
  );
}

function SubscriptionApp() {
  return (
    <>
      <HideChatWidget />
      <Subscription />
    </>
  );
}

function SupplementsApp() {
  useEffect(() => {
    // Load features from the GrowthBook API and initialize the SDK
    getFiGrowthBook().loadFeatures();
  }, []);

  // Set the attributes of the request/user
  getFiGrowthBook().setAttributes();

  return (
    <GrowthBookProvider growthbook={getFiGrowthBook().growthBook}>
      <HideChatWidget />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FeaturesReady>
          <Supplements />
        </FeaturesReady>
      </LocalizationProvider>
    </GrowthBookProvider>
  );
}

function MembershipUpgradeApp() {
  return (
    <>
      <MembershipUpgrade />
    </>
  );
}

function MonthlyUpgradeApp() {
  useEffect(() => {
    // Load features from the GrowthBook API and initialize the SDK
    getFiGrowthBook().loadFeatures();
  }, []);

  // Set the attributes of the request/user
  getFiGrowthBook().setAttributes();

  return (
    <GrowthBookProvider growthbook={getFiGrowthBook().growthBook}>
      <HideChatWidget />
      <MonthlyUpgrade />
    </GrowthBookProvider>
  );
}

function InAppSubscriptionPurchaseApp() {
  return (
    <>
      <HideChatWidget />
      <PurchaseSubscriptionRoutes />
    </>
  );
}

function InAppSubscriptionPurchaseBlockerApp() {
  return (
    <>
      <HideChatWidget />
      <PurchaseSubscriptionBlocker />
    </>
  );
}

function ReferralsApp() {
  return (
    <>
      <HideChatWidget />
      <Referrals />
    </>
  );
}

function RescuePlacementApp() {
  return (
    <>
      <HideChatWidget />
      <RescuePlacement />
    </>
  );
}

// Wrapping with the RUM HOC first seems to fix an issue where the shipping page would update the session store after
// creating a new user and then would stop processing the rest of the callbacks which update the address.
const ConfiguredApp = requireConfig(App);
const ConfiguredSeries3UpgradeApp = requireConfig(Series3UpgradeApp);
const ConfiguredMembershipUpgradeApp = requireConfig(MembershipUpgradeApp);
const ConfiguredMonthlyUpgradeApp = requireConfig(MonthlyUpgradeApp);
const ConfiguredSubscriptionApp = requireConfig(SubscriptionApp);
const ConfiguredSupplementsApp = requireConfig(SupplementsApp);
const ConfiguredReferralsApp = requireConfig(ReferralsApp);
const ConfiguredInAppSubscriptionPurchaseApp = requireConfig(InAppSubscriptionPurchaseApp);
const ConfiguredInAppSubscriptionPurchaseBlockerApp = requireConfig(InAppSubscriptionPurchaseBlockerApp);
const ConfiguredRescuePlacementApp = requireConfig(RescuePlacementApp);

function Series3AppRouter() {
  return (
    <>
      <Switch>
        <SentryRoute exact path={AppPaths.PurchaseSubscription.Root}>
          <ConfiguredInAppSubscriptionPurchaseBlockerApp />
        </SentryRoute>
        <SentryRoute path={AppPaths.PurchaseSubscription.PlanSelection()}>
          <ConfiguredInAppSubscriptionPurchaseApp requireLogin />
        </SentryRoute>
        <SentryRoute path={AppPaths.Series3Upgrade.Root}>
          <ConfiguredSeries3UpgradeApp requireLogin upgradeContext />
        </SentryRoute>
        <SentryRoute path={AppPaths.MembershipUpgrade.PlanSelection()}>
          <ConfiguredMembershipUpgradeApp requireLogin />
        </SentryRoute>
        <SentryRoute path={AppPaths.MonthlyUpgrade.PlanSelection()}>
          <ConfiguredMonthlyUpgradeApp requireLogin />
        </SentryRoute>

        <SentryRoute path={AppPaths.Subscription.SplashPageForDevice()}>
          <ConfiguredSubscriptionApp requireLogin />
        </SentryRoute>

        <SentryRoute path={AppPaths.Supplements.Root}>
          <ConfiguredSupplementsApp requireLogin />
        </SentryRoute>

        <SentryRoute exact path={AppPaths.Referrals}>
          <ConfiguredReferralsApp requireLogin />
        </SentryRoute>

        <SentryRoute exact path={AppPaths.Rescue.Placement}>
          <ConfiguredRescuePlacementApp requireLogin />
        </SentryRoute>

        <SentryRoute>
          <ConfiguredApp />
        </SentryRoute>
      </Switch>
    </>
  );
}

export default function Series3App() {
  return (
    <>
      <CJEventComponent />
      <ImpactIdentifyComponent />
      <div className={styles.container}>
        <Series3AppRouter />
      </div>
      {/* When popup-root is defined, popupjs-model will put modals inside it. We want it close to <body /> root */}
      <div id="popup-root" />
    </>
  );
}
