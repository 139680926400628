import React from 'react';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import Button from '../../../components/Button';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import { useHistory, useLocation } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import { BillingSubscription } from './SplashPage';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { setSupplementNextShipDateMutation, supplementsSubscriptionsQuery } from '../../../graphql-operations';
import { logInternalError } from '../../../lib/errors';
import { getLoadingOrErrorElement } from '../../../lib/util';
import * as types from '../../../types';
import ErrorMessage, { useError } from '../../../components/ErrorMessage/ErrorMessage';
import Loading from '../../../components/Loading';
import { useSelector } from 'react-redux';

interface SkipDeterrentState {
  subscription: BillingSubscription;
  showChangeFrequencyDeterrent: boolean;
  currentSubscriptionOption: types.ISupplementSubscriptionProduct[];
  subscriptionOptions: types.ISupplementSubscriptionProduct[];
}

export default function SkipDeterrent() {
  const pageName = 'Skip Deterrent';
  const history = useHistory();
  const location = useLocation<SkipDeterrentState>();
  const TIMEZONE = 'America/New_York';
  const { error, errorID, setError } = useError();
  const { subscription, showChangeFrequencyDeterrent, currentSubscriptionOption, subscriptionOptions } = location.state;
  const showDiscountDeterrent = useSelector(
    (state: types.AppState) => state.config.siteConfig.showDiscountDeterrent ?? false,
  );
  events.pageLoad({ subscriptionId: subscription.id }, pageName);

  const previewSkipDate = subscription.supplementShipmentDates!.previewSkippingShipmentDate;

  const [setShipDateMutation, setShipDateMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_setSupplementNextShipDate,
    types.gqlTypes.ECOMMERCE_setSupplementNextShipDateVariables
  >(setSupplementNextShipDateMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.setSupplementNextShipDate?.success;
      if (success) {
        history.push({
          pathname: AppPaths.Supplements.Root,
          state: { toast: { success: true, change: 'updated' }, refetch: false },
          search: window.location.search,
        });
        events.setShipDate({ subscriptionId: subscription.id });
      }
      setError(`Failed to update next ship date: ${data.setSupplementNextShipDate?.reasonForFailure}`);
    },
    onError(err) {
      setError(err.message);
      logInternalError(err);
    },
  });

  const setShipDateLoading = getLoadingOrErrorElement(setShipDateMutationState.loading, null);

  const handleCancel = () => {
    events.skipNextShipmentDeterrentDeclined({ subscriptionId: subscription.id });
    if (showChangeFrequencyDeterrent) {
      history.push({
        pathname: AppPaths.Supplements.ChangeFrequency(subscription.id),
        state: { subscription, currentSubscriptionOption, subscriptionOptions },
        search: window.location.search,
      });
    } else if (showDiscountDeterrent) {
      history.push({
        pathname: AppPaths.Supplements.Discount(subscription.id),
        state: { subscription },
        search: window.location.search,
      });
    } else {
      history.push({
        pathname: AppPaths.Supplements.Survey(subscription.id),
        state: { subscription },
        search: window.location.search,
      });
    }
  };

  const handleSkip = () => {
    events.skipNextShipmentDeterrentAccepted({ subscriptionId: subscription.id });
    const dateWithTimeZone = DateTime.fromISO(previewSkipDate).setZone(TIMEZONE).set({ hour: 6, minute: 0 }).toJSDate();
    setShipDateMutation({
      variables: {
        input: {
          recurlySubscriptionId: subscription.id,
          nextShipDate: dateWithTimeZone,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.goBackFromPage({ subscriptionId: subscription.id }, pageName)}
          closeButtonAnalyticsEvent={() => events.closePage({ subscriptionId: subscription.id }, pageName)}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.largeHeader}>
            <h1>Skip next shipment</h1>
          </div>

          {setShipDateLoading ? (
            <Loading className={styles.loading} />
          ) : (
            <div className={styles.content}>
              <p>Not ready for your next shipment? No problem.</p>

              <div className={styles.highlight}>
                <div className={styles.header}>New shipment date</div>
                <div className={styles.title}>{DateTime.fromISO(previewSkipDate).toFormat('cccc, LLL d')}</div>
              </div>
              <p>Your next supplement order will ship on your new scheduled shipment date, above.</p>
              {error && <ErrorMessage errors={[error]} errorID={errorID} />}
            </div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={handleSkip}>
            Skip next shipment
          </Button>
          <Button className={styles.button} onClick={handleCancel} tertiary>
            Continue with Cancellation
          </Button>
        </div>
      </div>
    </>
  );
}
